import { Component, ErrorInfo, ReactNode } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { Fallback } from './fallback/Fallback';

type Props = {
  children: ReactNode
}

type ErrorLog = {
  type: 'RuntimeError' | 'ReactError'
  name: string
  message: string
  url: string
  source?: string
  lineno?: number
  colno?: number
  stack?: string
  componentStack?: string | null
  digest?: string | null
}

export class ErrorBoundary extends Component<Props> {
  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.onerror = (message, source, lineno, colno, error) => {
        this.#processRuntimeError(message.toString(), source, lineno, colno, error);
        return true;
      };
    }
  }

  #processRuntimeError = (message: string, source?: string, lineno?: number, colno?: number, error?: Error) => {
    this.#logError({
      type: 'RuntimeError',
      name: error?.name || 'Error',
      message,
      source,
      lineno,
      colno,
      stack: error?.stack,
      url: window.location.href,
    });
  };

  #processReactError = (error: Error, info: ErrorInfo) => {
    this.#logError({
      type: 'ReactError',
      name: error.name,
      message: error.message,
      stack: error.stack,
      componentStack: info.componentStack,
      digest: info.digest,
      url: window.location.href,
    });
  };

  #logError = (error: ErrorLog) => {
    fetch('/collector', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(error),
      // eslint-disable-next-line no-console
    }).catch(console.error);
  };

  render() {
    return (
      <ReactErrorBoundary
        onError={this.#processReactError}
        fallback={<Fallback />}
      >
        {this.props.children}
      </ReactErrorBoundary>
    );
  }
}
